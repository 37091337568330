const DiadaoUtils = {
    // Setup
    init() {
        this.onResize();
        this.setDeviceType(window.getWindowWidth());

        jQuery(window).trigger('resize');
    },

    addLeadingZero(number) {
        if (parseInt(number, 10) < 10) {
            number = `0${number}`;
        }
        return number;
    },

    // Actions on window resize
    onResize() {
        const self = this;
        let oldWidth;
        let oldHeight;

        jQuery(window).resizeThrottle(function (p) {
            const $window = jQuery(this);
            const windowWidth = window.getWindowWidth();
            const windowHeight = window.getWindowHeight();

            const ratio = Math.floor(windowWidth / windowHeight);
            let orientation = 'landscape';
            if (ratio < 1) {
                orientation = 'portrait';
            }
            let orientationChanged = false;
            if (Config_Diadao.orientation !== orientation) {
                orientationChanged = true;
                Config_Diadao.orientation = orientation;
            }
            self.setDeviceType(windowWidth);
            if (oldWidth !== windowWidth || oldHeight !== windowHeight
                || (typeof p !== 'undefined' && typeof p.force !== 'undefined' && p.force === true)) {
                PubSub.publish(Config_Diadao.events.window.resize, {windowWidth, windowHeight, orientationChanged, ratio});
                oldWidth = windowWidth;
                oldHeight = windowHeight;
                oldHeight = windowHeight;
            }
        });
    },

    formatDate(format, separator, date) {
        format = format || 'dd-mm-yy';
        format = format.split(separator);
        for (let i = 0; i < format.length; i++) {
            switch (format[i]) {
                case 'dd':
                    format[i] = date.getDate();
                    break;
                case 'mm':
                    format[i] = date.getMonth() + 1;
                    break;
                case 'yy':
                    format[i] = date.getFullYear();
                    break;
                default:
                    break;
            }
            if (parseInt(format[i], 10) < 10) {
                format[i] = `0${format[i]}`;
            }
        }
        return format.join(separator);
    },

    getScrollbarWidth() {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

        document.body.appendChild(outer);

        const widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = 'scroll';

        // add innerdiv
        const inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);

        const widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        // stores value
        Config_Diadao.scrollbarWidth = widthNoScroll - widthWithScroll;

        return Config_Diadao.scrollbarWidth;
    },

    setDeviceType(windowWidth) {
        Config_Diadao.deviceType = 'small';
        if (windowWidth > Config_Diadao.gridBreakPoint.tablet) {
            Config_Diadao.deviceType = 'large';
        } else if (windowWidth > Config_Diadao.gridBreakPoint.mobile) {
            Config_Diadao.deviceType = 'medium';
        }
    },

    lazyLoad($selector) {
        

        const lazyOffset = Config_Diadao.lazyLoad.offset || 500;
        $selector.imagesLoaded({background: true}, () => {
            $selector.unveil(lazyOffset, function () {
                const $this = jQuery(this);
                if ($this.is('img')) {
                    $this.prev().css({"top": jQuery(this).height() / 2 + "px"});
                    $this.load(() => {
                        $this.addClass('loaded');
                        PubSub.publish('bs.scroll.refresh');
                        $this.siblings('.lazy-loading-icon-img').fadeOut(function () {
                            jQuery(this).remove();
                        });
                    });
                } else {
                    $this.imagesLoaded({background: true}, () => {
                        $this.addClass('loaded');
                        PubSub.publish('bs.scroll.refresh');
                    });
                }
            });
        });
    },

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },

    getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },

    setBodyScrollTop() {
        

        const scrollTop = jQuery(window).scrollTop();
        const bodyStyle = document.body.style;
        bodyStyle.setProperty('--scrollTop', scrollTop);
    },

    goToBodyScrollTop() {
        

        if (typeof Config_Diadao.smoothScrollInstance !== 'undefined') {
            const smoothScroll = Config_Diadao.smoothScrollInstance;
            smoothScroll.scrollTo(window.getComputedStyle(document.body).getPropertyValue('--scrollTop'));
        } else {
            jQuery(window).scrollTop(window.getComputedStyle(document.body).getPropertyValue('--scrollTop'));
        }
    },

    getPercentageOfWidowHeight(percentage) {
        const result = (window.getWindowHeight() * percentage) / 100;

        return Math.ceil(parseFloat(result));
    },

    normalizeWheelDelta(e) {
        if (e.detail) {
            if (e.wheelDelta)
                return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1); // Opera
            else
                return -e.detail / 3; // Firefox
        } else if (e.wheelDelta) {
            return e.wheelDelta / 120; // IE,Safari,Chrome
        }

        // For when scroll event is fired
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > this.lastScrollTop) {
            // downscroll code
            this.lastScrollTop = st <= 0 ? 0 : st;
            return -1;
        }

        // upscroll code
        this.lastScrollTop = st <= 0 ? 0 : st;
        return 1;
    },
};
jQuery.fn.reverse = [].reverse;

/**
 * Returns element value, excluding excluded options and values below minimum and greater than maximum
 * @return {object}
 */
(function( $ ) {
    jQuery.prototype.getElementByAttributeValue = function (attribute, value) {
        return jQuery(this).filter((i, item) => {
            let result = false;
            if (typeof value === 'string') {
                result = item[attribute].toString() === value.toString();
            } else if (typeof value === 'number' || typeof value === 'boolean') {
                result = parseInt(item[attribute], 10) === parseInt(value, 10);
            }
            return result;
        })[0];
    };
})( jQuery );

window.getWindowHeight = function () {
    var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return height;
};

window.getWindowWidth = function () {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width;
};

window.navigator.sayswho = (function () {
    const ua = window.navigator.userAgent;
    let tem = '';
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}());

/* eslint-disable */
const debounce = function (a, b, c) {
    let d;
    return function () {
        let e = this, f = arguments;
        clearTimeout(d), d = setTimeout(function () {
            d = null, c || a.apply(e, f);
        }, b), c && !d && a.apply(e, f);
    };
};

function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last,
        deferTimer;
    return function () {
        var context = scope || this;

        var now = +new Date,
            args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

(function( $ ) {
    jQuery.extend({
        replaceTag: function (element, tagName, withDataAndEvents, deepWithDataAndEvents) {
            var newTag = jQuery("<" + tagName + ">")[0];
            // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
            jQuery.each(element.attributes, function() {
                newTag.setAttribute(this.name, this.value);
            });
            jQuery(element).children().clone(withDataAndEvents, deepWithDataAndEvents).appendTo(newTag);
            return newTag;
        }
    });

    jQuery.fn.extend({
        replaceTag: function (tagName, withDataAndEvents, deepWithDataAndEvents) {
            // Use map to reconstruct the selector with newly created elements
            return this.map(function() {
                return jQuery.replaceTag(this, tagName, withDataAndEvents, deepWithDataAndEvents);
            })
        }
    });
})( jQuery );

/* eslint-enable */

export default DiadaoUtils;
export { debounce, throttle };
