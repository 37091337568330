import PubSub from 'pubsub-js';
import DiadaoUtils from './utils.js';

/**
 * Retargeting
 *
 */

class DiadaoRetargeting 
{

    constructor( params ) 
    {
        const that = this;
        let diadaoPageId = null;
        if( window['diadao_page_id'] !== undefined )
        {
            diadaoPageId = window['diadao_page_id'];
        }
        const pageId = diadaoPageId;
        sessionStorage
        that.init();
    }

    init() 
    {
        const that = this;

        let debug = true;

        let t_checkCookies = true;
        if( 
            window['diadao_retargeting_check_cookies'] !== undefined 
            && window['diadao_retargeting_check_cookies'] !== "" 
            && parseInt( window['diadao_retargeting_check_cookies'] ) == 0 
        )
        {
            t_checkCookies = false;
        }

        const check_cookies = t_checkCookies;
        
        const $panelRetargeting = jQuery( 'body').find( '.panel-retargeting' );
        const defaultConfig = {
            timeout: 20000, // ms
            cookieDuration: 1, // days,
            timeoutBeforeAppear: 300, // ms
            inactivityTimeoutBeforeAppear: 20000, // ms
            events: {
                open: "",
                close: ""
            }
        };

        that.Config_Diadao = jQuery.extend(true, {}, defaultConfig, Config_Diadao.retargeting);

        if( debug )
        {
            console.log( '-> retargeting debug 1' );
            console.log( 'config', that.Config_Diadao );
            console.log( 'panel retargeting', $panelRetargeting.length );
            console.log( 'check cookies', check_cookies );
        }

        if (
            $panelRetargeting !== undefined
            && $panelRetargeting.length
        ) 
        {
            console.log( 'session storage', sessionStorage.getItem(`diadao-retargeting-show`) );

            // On vérifie si le cookie retargeting n'existe pas
            if (
                //!DiadaoUtils.getCookie('retargeting') 
                (
                    sessionStorage 
                    && sessionStorage.getItem(`diadao-retargeting-show`) === null
                )
                || !check_cookies
            ) 
            {
                if( debug )
                {
                    console.log( '-> retargeting debug 2' );
                }

                setTimeout(() => {
                    // Au bout de X secondes sur la page si le curseur de la souris
                    // sort du site par le haut le panel Retargeting s'ouvre
                    jQuery( 'body, html').on('mousemove.retargeting touchmove.retargeting', that.handleMouseMove.bind(this));
                    if( debug )
                    {
                        console.log( '-> retargeting debug 3' );
                    }
                }, that.Config_Diadao.timeout);

                that.handleCloseRetargeting();
                that.toggleShareButtons();
                //that.getInputFieldPlaceholder();
            } 
            else 
            {
                // cookie found - do not init retargeting
                if( debug )
                {
                    console.log( '-> retargeting debug 4' );
                }

                // $panelRetargeting.remove();
            }
        }
    }

    handleMouseMove(event) {
        
        const that = this;
        const $html = jQuery( 'html' );

        console.log( 'debug handle mouse' );

        if (that.timeoutBeforeAppearToken) {
            window.clearTimeout(that.timeoutBeforeAppearToken);
            that.timeoutBeforeAppearToken = 0;
        }

        if (that.inactivityTimeoutBeforeAppearToken) {
            window.clearTimeout(that.inactivityTimeoutBeforeAppearToken);
            that.inactivityTimeoutBeforeAppearToken = 0;
        }

        event = event || window.event; // IE-ism

        // If mouse is on the top of window
        if (event.pageY - $html.scrollTop() < 10) {
            that.timeoutBeforeAppearToken = setTimeout(() => {
                that.showPanel();
            }, that.Config_Diadao.timeoutBeforeAppear);
        } else {
            clearTimeout(that.timeoutBeforeAppearToken);
            that.timeoutBeforeAppearToken = setTimeout(() => {
                that.showPanel();
            }, that.Config_Diadao.inactivityTimeoutBeforeAppear);
        }
    }

    showPanel() {
        const that = this;
        
        console.log( 'show retargeting' );

        const $retargetingPanel = jQuery( '.panel-retargeting' );
        const $body = jQuery( 'body' );
        const $html = jQuery( 'html' );

        jQuery( 'body, html').off('mousemove.retargeting touchmove.retargeting' );
        setTimeout(() => {
            if (this.timeoutBeforeAppearToken) {
                window.clearTimeout(this.timeoutBeforeAppearToken);
                this.timeoutBeforeAppearToken = 0;
            }
            if (this.inactivityTimeoutBeforeAppearToken) {
                window.clearTimeout(this.inactivityTimeoutBeforeAppearToken);
                this.inactivityTimeoutBeforeAppearToken = 0;
            }
        }, 0);

        sessionStorage.setItem(`diadao-retargeting-show`, Date.now());
        //DiadaoUtils.setCookie('retargeting', true, that.Config_Diadao.cookieDuration);
        if(
            $retargetingPanel.find( '.medias-slideshow' ) !== undefined
            && $retargetingPanel.find( '.medias-slideshow' ).length
        )
        {
            //$retargetingPanel.find( '.medias-slideshow').slick(); // 'resiz e'
            let t_swiperParent = jQuery( '.swiper-container.retargetingSwiper' ).parent( );
            let t_retargetingPanelSwiper = new Swiper( '.swiper-container.retargetingSwiper', {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 0,
                loop: false,
                effect: "slide",
                clickable: true,
                simulateTouch: true,
                preventClicksPropagation: false,
                preventClicks: false,
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next.retargetingSwiper",
                    prevEl: ".swiper-button-prev.retargetingSwiper",
                },
                pagination: {
                    el: '.swiper-pagination.retargetingSwiper',
                    clickable: true,
                },
                on: {
                    init: function( e )
                    { 
                       t_swiperParent.addClass('is-initialized' );
                    },
                    afterInit: function( e )
                    {
                        console.log( 'init swiper retargeting' );
                        // if( 
                        //     window['DiadaoStarterManager'] !== undefined
                        //     && window['DiadaoStarterManager'] !== null
                        // )
                        // {
                        //     window['DiadaoStarterManager'].update_lazy_load( $( 'body' ), true );
                        //     window['DiadaoStarterManager'].init_swiper_vanilla( e.el );
                        // }

                        setTimeout(function(){
                            t_swiperParent.find( '.swiper-slide.swiper-slide-init').removeClass('swiper-slide-init' );
                        }, 2000);
                    }
                },
            });

        }

        $retargetingPanel.addClass('active' );
        $body.addClass('retargeting--opened' );
        $html.addClass('retargeting--opened' );
        $body.addClass('diadao-panel-plugin-opened' );
        $html.addClass('diadao-panel-plugin-opened' );

        if (
            jQuery( '.panel-retargeting__overlay') === undefined
            || jQuery( '.panel-retargeting__overlay') === null
            || jQuery( '.panel-retargeting__overlay').length === 0 
        ) 
        {
            $body.append( '<div class="panel-retargeting__overlay"></div>' );
        }
        //PubSub.publish(Config_Diadao.retargeting.events.open);
    }

    handleCloseRetargeting() {
        const that = this;
        

        const $body = jQuery( 'body' );
        $body.on('click.retargeting', '.panel-retargeting__overlay', that.closeRetargeting);
        $body.on('click.retargeting', '.panel-retargeting__close', that.closeRetargeting);
    }

    closeRetargeting() {
        

        const $overlay = jQuery( '.panel-retargeting__overlay' );
        const $body = jQuery( 'body' );
        const $html = jQuery( 'html' );
        const $retargetingPanel = jQuery( '.panel-retargeting' );

        $overlay.remove();
        $body.removeClass('retargeting--opened' );
        $html.removeClass('retargeting--opened' );
        $body.removeClass('diadao-panel-plugin-opened' );
        $html.removeClass('diadao-panel-plugin-opened' );

        $retargetingPanel.removeClass('active' );
        //PubSub.publish(Config_Diadao.retargeting.events.close);
    }

    toggleShareButtons() {
        

        const $socialButton = jQuery( '.social-button' );
        const $retargetingShare = jQuery( '.panel-retargeting__share__socials' );
        const $closeSocial = jQuery( '.close-socials' );
        if ($socialButton) {
            $socialButton.click(() => {
                $retargetingShare.toggleClass('active' );
            });
        }

        if ($closeSocial) {
            $closeSocial.click(() => {
                $retargetingShare.removeClass('active' );
            });
        }
    }

    getInputFieldPlaceholder () {
        
        const $emailContainer = jQuery( '.panel-retargeting__footer form.newsletter .form-element.email-container' );

        if($emailContainer){
            const $label = $emailContainer.find( 'label' );
            const $input = $emailContainer.find( 'input' );

            if($label){
                const labelText = $label.text();
                $label.remove();

                if($input){
                    const placeholder = $input.attr('placeholder' );

                    if(!placeholder){
                        $input.attr('placeholder', labelText);
                    }
                }
            }
        }
    }
}

export default DiadaoRetargeting;
